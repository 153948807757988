import React from 'react'
import { Box, Typography } from '@mui/material'

const BlockTitle = ({ title }) => {
  return (
    <Box
      sx={{
        borderBottom: '1px solid',
        pb: 1,
        mb: 4
      }}
    >
      <Typography variant='h2' sx={{fontWeight: 400}}>{title}</Typography>
    </Box>
  )
}

export default BlockTitle