import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Hero = () => {
  return (
    <StaticImage
      src="../images/header-info-2024.png"
      alt="17 Hippies - 9000 Nächte"
    />
  )
}

export default Hero