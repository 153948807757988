import React from 'react'
import { Box } from '@mui/material'

const TopInfo = ({ html }) => {
  return (
    <Box
      sx={{
        backgroundColor: 'grey.50',
        borderRadius: '1rem',
        mt: {xs: 5, md: 10},
        px: {xs: 2, md: 3},
        py: {xs: 0.5, md: 1}
      }}
    >
      <Box
        id='index'
        dangerouslySetInnerHTML={{ __html: html }}
        sx={{
          'a': {
            textDecoration: 'none',
            color: 'primary.main',
            '&:hover': {
              color: 'primary.dark'
            }
          }
        }}
      />

    </Box>
  )
}

export default TopInfo